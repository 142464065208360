export enum TenantFeatures {
  Qualifications = 'Qualifications',
  DynamicsIntegration = 'DynamicsIntegration',
  ElsmartIntegration = 'Elsmart',
  FlexiSaldo = 'FlexiSaldo'
}

export function hasTenantFeature(
  tenant: { tenantFeatures: TenantFeatures[] } | null,
  feature: TenantFeatures,
): boolean {
  return tenant?.tenantFeatures.includes(feature) || false;
}
