<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import UserProfileExternal from './UserProfileExternal.vue';
import UserProfilePassword from './UserProfilePassword.vue';
import UserProfileTimer from './UserProfileTimer.vue';
import UserProfileUser from './UserProfileUser.vue';
import DfTabs from '/@elements/DfTabs/DfTabs.vue';
import { awaitTenant, useTenantStore } from '/@stores/tenant';
import { hasTenantFeature, TenantFeatures } from '/@utilities/tenant';

const tenantStore = useTenantStore();

const loading = ref(0);
const { tenant } = storeToRefs(tenantStore);
const tabs = ref([
  { name: 'Profil', id: 'profile', icon: 'f007' },
  { name: 'Passord', id: 'password', icon: 'f023' },
]);

async function initialize() {
  loading.value++;

  await awaitTenant;

  if (hasTenantFeature(tenant.value, TenantFeatures.FlexiSaldo)) {
    tabs.value.push({ name: 'Andre kilder', id: 'external', icon: 'f543' });
  }

  loading.value--;
}

initialize();
</script>

<template>
  <div class="page page--wide">
    <df-header>Min profil</df-header>

    <df-loading v-if="loading" />

    <df-tabs :tabs="tabs">
      <template #tab:profile>
        <UserProfileUser />
      </template>

      <template #tab:password>
        <UserProfilePassword />
      </template>

      <template #tab:time>
        <UserProfileTimer />
      </template>

      <template #tab:external>
        <UserProfileExternal />
      </template>
    </df-tabs>
  </div>
</template>

<style scoped></style>
