import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import { api, genericApiErrorHandler } from '../utilities/api';
import { Modules } from '/@types/ids';
import { CurrentUser, FlexiSaldo, RolePermission, UserForm } from '/@types/user';
import * as Sentry from '@sentry/vue';

let resolveAwaitUser;
export const awaitUser: Promise<CurrentUser> = new Promise(
  (resolve) => (resolveAwaitUser = resolve),
);

function keyValueMapper(arr: Array<any>) {
  return arr.map((i) => ({ id: i.Id, name: i.Name }));
}

function mapUser(user: any): CurrentUser {
  return {
    id: user.Id,
    name: user.FullName,
    roles: keyValueMapper(user.Roles),
    tenant: {
      id: user.Tenant.Id,
      moduleIds: user.Tenant.Modules.map(({ Id }) => Id),
    },
    hiddenModuleIds: user.BlacklistedModuleIds,
    firstName: user.FirstName,
    lastName: user.LastName,
    phoneNumber: user.Mobile,
    employeeNumber: user.EmployeeNumber,
    birthdate: user.DateOfBirth,
    hmsCardNumber: user.hmsCardNumber,
    betaUser: user.BetaUser,
    showStatusNotificationPage: user.ShowStatusNotificationPage,
    qualificationIds: user.QualificationIds,
    permissions: user.Permissions,
  };
}

interface CurrentTenant {
  tenantFeatures: string[];
}

function extractLastName(name: string) {
  if (!name) return null;
  const splitNames = name.split(' ');
  return [splitNames.pop(), splitNames.join(' ')];
}

export const useUserStore = defineStore('user', () => {
  const user = ref<CurrentUser | null>(null);
  const tenant = ref<CurrentTenant | null>(null);

  const hasModule = computed(
    () => (moduleId: Modules) =>
      user.value?.tenant.moduleIds.includes(moduleId) &&
      !user.value?.hiddenModuleIds.includes(moduleId),
  );

  const hasRolePerm = computed(
    () => (rolePermission: RolePermission) => user.value?.permissions.includes(rolePermission),
  );

  function loadUser(skipCache = false) {
    return api
      .get(`shared/users/current?skipCache=${skipCache}`)
      .then(({ data }) => {
        user.value = mapUser(data);
        localStorage.setItem('currentUser', JSON.stringify(user.value));

        Sentry.setUser({
          id: user.value.id,
          email: user.value.email,
          username: user.value.name,
          tenantId: user.value.tenant.id,
          tenantName: user.value.tenant.name,
        });

        resolveAwaitUser(user.value);
      })
      .catch(genericApiErrorHandler);
  }

  function loadFlexiSaldo(): Promise<Map<number, FlexiSaldo>> {
    return api
      .get(`shared/users/current/flexisaldo`)
      .then(({ data }) => {
        return new Map(
          data.map((item: any, index: number) => [
            index,
            { balance: item.Balance, description: item.Description },
          ]),
        );
      });
  }

  async function updateUser(form: UserForm) {
    await loadUser();

    const name = extractLastName(form.name);

    return api
      .put(`userprofile/profile`, {
        FirstName: name[1],
        LastName: name[0],
        Mobile: form.phoneNumber,

        HmsCardNumber: user.value.hmsCardNumber,
        EmployeeNumber: user.value.employeeNumber,
        BetaUser: user.value.betaUser,
        QualificationIds: user.value.qualificationIds,
        ShowStatusNotificationPage: user.value.showStatusNotificationPage,
      })
      .then(() => loadUser())
      .catch(genericApiErrorHandler);
  }

  function loadTenant() {
    return api.get(`shared/users/currenttenant`).then(({ data }) => {
      tenant.value = {
        tenantFeatures: data.TenantFeatures,
      };
    });
  }

  return { user, loadUser, loadFlexiSaldo, updateUser, tenant, loadTenant, hasModule, hasRolePerm };
});
