<script setup lang="ts">
import { ref } from 'vue';
import { useUserStore } from '/@stores/user';
import { FlexiSaldo } from '/@types/user';
import { useAlert } from '/@elements/DfAlert/DfAlert.vue';

const userStore = useUserStore();
const alert = useAlert();

const flexiSaldo = ref<Map<number, FlexiSaldo>>(new Map());
const loading = ref(0);

async function initialize() {
  loading.value++;

  userStore
    .loadFlexiSaldo()
    .then((data) => (flexiSaldo.value = data))
    .catch((response) => {
      const error =
        response?.status === 503
          ? response?.data?.Details?.[0] ?? 'Kan ikke hente saldoliste fra ekstern tjeneste'
          : 'Ukjent feil ved lasting av saldoliste';

      alert.write(error, 'danger');
    });

  loading.value--;
}

initialize();
</script>

<template>
  <section class="sub-page">
    <df-loading v-if="loading" />

    <df-header>Saldoliste</df-header>

    <df-alert v-model="alert" />

    <div v-if="flexiSaldo.size">
      <dl class="dl card">
        <div v-for="[id, saldo] in flexiSaldo" :key="id" class="dl__group">
          <dt class="dl__topic">{{ saldo.description }}</dt>
          <dd class="dl__description">{{ saldo.balance }}</dd>
        </div>
      </dl>
    </div>
  </section>
</template>

<style scoped>
.sub-page {
  gap: var(--gap-lg);
}

.dl {
  padding: 0;
}
</style>
